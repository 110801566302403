import { createRef, useReducer } from "react";

import structuredClone from "@ungap/structured-clone";

export type CaseFormAction =
  | { type: "clearFocusField" | "gotoNextTab" | "setSubmitted" }
  | { type: "changeTab" | "setId" | "setSaved"; payload: number }
  | { type: "gotoField"; payload: Form.Field }
  | { type: "update"; payload: Form.UpdateData };

export type CaseFormDispatch = (action: CaseFormAction) => void;

export interface CaseFormState {
  id?: number;
  form: Form.Form;
  fields: Form.Field[];
  data: Form.Data;
  currentTab: number;
  saved: boolean;
  submitted: boolean;
  summary: boolean;
  focusField?: Form.Field;
}

interface Props {
  form: Form.Form;
  data: Form.Data;
  tab_id: string;
  id: number;
  submitted: boolean;
  summary: boolean;
}

function prepareForm(form: Form.Form) {
  const newForm = structuredClone<Form.Form>(form);
  newForm.tabs.forEach((tab) => {
    tab.blocks.forEach((block) => {
      block.tab = tab;
      block.fields.forEach((row) => {
        row.forEach((field) => {
          field.tab = tab;
          field.block = block;
          field.row = row;
          field.ref = createRef();
        });
      });
    });
  });
  return newForm;
}

function extractFields(form: Form.Form) {
  let fields: Form.Field[] = [];
  form.tabs.forEach(function (tab) {
    tab.blocks.forEach(function (block) {
      block.fields.forEach(function (row) {
        fields = fields.concat(row);
      });
    });
  });
  return fields;
}

function findCurrentTab(form: Form.Form, id?: string): number {
  let currentTab = 0;
  if (id) {
    form.tabs.forEach((t, i) => {
      if (t.id == id) {
        currentTab = i;
      }
    });
  }
  return currentTab;
}

function calculate(
  formula: string,
  state: CaseFormState,
  nextData: Form.Data
): string {
  let values = Object.assign({}, state.data);

  if (nextData) {
    values = Object.assign(values, nextData);
  }

  const calculation = formula.replace(/{([\w\d_]+)}/, (_, key: string) => {
    const field = state.fields.filter((f) => f.name == key)[0];
    let value = values[key] as string;

    // Look up the calculation value from the options table
    if ("options" in field && field.options[0].length >= 3) {
      value = field.options.filter((o) => o[1] === value)[0] as string;
      if (value) {
        value = value[2];
      }
    }

    if (value) {
      return `${parseFloat(value)}`;
    } else {
      return "0";
    }
  });

  const result = (0, eval)(calculation) as number;

  return `${result}` || "";
}

function calculatedValues(state: CaseFormState, nextData: Form.Data) {
  const obj: Form.Data = {};
  state.fields.forEach((field) => {
    if (field.type === "calculation") {
      obj[field.name] = calculate(field.formula, state, nextData);
    }
  });
  return obj;
}

function gotoField(state: CaseFormState, field: Form.Field) {
  return {
    ...state,
    currentTab: state.form.tabs.indexOf(field.tab),
    focusField: field
  };
}

function update(
  state: CaseFormState,
  updateData: Form.UpdateData
): CaseFormState {
  const newData: Form.Data = {};

  Object.keys(updateData).forEach((key) => {
    const newValue = updateData[key];

    if (typeof newValue === "function") {
      newData[key] = newValue(state.data[key]);
    } else {
      newData[key] = newValue;
    }
  });

  return {
    ...state,
    data: { ...state.data, ...newData, ...calculatedValues(state, newData) },
    saved: false
  };
}

function reducer(state: CaseFormState, action: CaseFormAction): CaseFormState {
  switch (action.type) {
    case "clearFocusField":
      return { ...state, focusField: null };
    case "changeTab":
      return { ...state, currentTab: action.payload };
    case "gotoField":
      return gotoField(state, action.payload);
    case "gotoNextTab":
      if (state.currentTab + 1 < state.form.tabs.length) {
        return { ...state, currentTab: state.currentTab + 1 };
      }
      return state;
    case "update":
      return update(state, action.payload);
    case "setId":
      return { ...state, id: action.payload };
    case "setSaved":
      return { ...state, saved: true, id: action.payload };
    case "setSubmitted":
      return { ...state, submitted: true };
    default:
      throw new Error();
  }
}

function initialData(data: Form.Data, state: CaseFormState) {
  const initialData: Form.Data = {};
  state.fields.forEach((field) => {
    const defaultValue = field.type == "check" ? false : "";
    initialData[field.name] = data[field.name] || defaultValue;
  });
  return { ...initialData, ...calculatedValues(state, initialData) };
}

function initialState(props: Props): CaseFormState {
  const form = prepareForm(props.form);
  const state: CaseFormState = {
    data: {},
    form: form,
    fields: extractFields(form),
    id: props.id,
    currentTab: findCurrentTab(form, props.tab_id),
    saved: false,
    submitted: props.submitted || false,
    summary: props.summary || false
  };

  return { ...state, data: initialData(props.data, state) };
}

export default function useCaseForm(
  props: Props
): [CaseFormState, CaseFormDispatch] {
  const [state, dispatch] = useReducer(reducer, props, initialState);
  return [state, dispatch];
}
