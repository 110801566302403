export interface Attachment {
  id: number;
  filename: string;
}

export function attachmentPath(file: Attachment) {
  let extension = "";
  if (file.filename.match(/\./)) {
    extension = "." + file.filename.split(".").reverse()[0];
  }

  return `/vedlegg/${file.id}${extension}`;
}
