import { useState, ChangeEvent } from "react";

import Error from "./Error";
import Field from "./Field";
import Label from "./Label";
import useInput, { nameToId } from "./useInput";

interface ModifyDateOptions {
  year?: number;
  month?: number;
  date?: number;
  time?: string;
}

const months = [
  "Januar",
  "Februar",
  "Mars",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Desember"
];

function defaultDate(offset = 0) {
  const coeff = 1000 * 60 * 60;
  return new Date(
    Math.round(new Date().getTime() / coeff) * coeff +
      coeff +
      1000 * 60 * offset
  );
}

function modifyDate(original: Date, options: ModifyDateOptions = {}): Date {
  const newDate = new Date(original);
  if (Object.prototype.hasOwnProperty.call(options, "year")) {
    newDate.setFullYear(options.year);
  }
  if (Object.prototype.hasOwnProperty.call(options, "month")) {
    newDate.setMonth(options.month);
  }
  if (Object.prototype.hasOwnProperty.call(options, "date")) {
    newDate.setDate(options.date);
  }
  if (
    Object.prototype.hasOwnProperty.call(options, "time") &&
    options.time.match(/^[\d]{1,2}(:[\d]{1,2})?$/)
  ) {
    newDate.setHours(parseInt(options.time.split(":")[0]));
    newDate.setMinutes(parseInt(options.time.split(":")[1] || "0"));
  }
  return newDate;
}

export function parseDate(date: Date | string | null) {
  if (!date) {
    return null;
  }
  if (typeof date === "string") {
    return new Date(date);
  } else {
    return date;
  }
}

function timeToString(time: Date): string {
  return time.toTimeString().slice(0, 5);
}

function dayOptions(value: Date): number[] {
  const date = parseDate(value);
  const year = date.getFullYear();
  const month = date.getMonth();
  const dates: number[] = [];
  for (let i = 1; i <= 35; i++) {
    if (new Date(year, month, i).getMonth() == month) {
      dates.push(i);
    }
  }
  return dates;
}

function yearOptions(): number[] {
  const start = 2009;
  const years: number[] = [];
  for (let i = start; i <= new Date().getFullYear() + 5; i++) {
    years.push(i);
  }
  return years;
}

export default function DateTimeSelect(props: FormInput.DateTimeSelect) {
  const { label, name } = props;

  const initializer = () => {
    return parseDate(props.value) || defaultDate();
  };

  const [timeStr, setTimeStr] = useState("");
  const { value, setValue, error } = useInput<FormInput.DateTimeSelect>(
    props,
    initializer
  );

  const classes = ["row", "date-select"];

  const changeDate = (options: ModifyDateOptions = {}) => {
    const newDate = modifyDate(value, options);

    setValue(newDate);
    setTimeStr(timeToString(newDate));
  };

  const handleChange =
    (attr: string) =>
    (
      evt: ChangeEvent<HTMLSelectElement> | React.FocusEvent<HTMLInputElement>
    ) => {
      changeDate({ [attr]: evt.target.value });
    };

  return (
    <div className={classes.join(" ")}>
      <input type="hidden" name={name} value={value.toJSON()} />
      <Field type="select" size={2}>
        <Label htmlFor={nameToId(name)} label={`${label}:`} />
        <div className={"select-wrapper " + (props.disabled ? "disabled" : "")}>
          <select
            onChange={handleChange("date")}
            disabled={props.disabled || false}
            value={value.getDate()}>
            {dayOptions(value).map((d, i) => (
              <option key={i} value={d}>
                {d}
              </option>
            ))}
          </select>
        </div>
      </Field>
      <Field type="select" size={2}>
        <div className={"select-wrapper " + (props.disabled ? "disabled" : "")}>
          <select
            onChange={handleChange("month")}
            disabled={props.disabled || false}
            value={value.getMonth()}>
            {months.map((m, i) => (
              <option key={i} value={i}>
                {m}
              </option>
            ))}
          </select>
        </div>
      </Field>
      <Field type="select" size={2}>
        <div className={"select-wrapper " + (props.disabled ? "disabled" : "")}>
          <select
            onChange={handleChange("year")}
            disabled={props.disabled || false}
            value={value.getFullYear()}>
            {yearOptions().map((y, i) => (
              <option key={i} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </Field>
      {!props.hideTime && (
        <Field type="text" size={2}>
          <input
            type="text"
            size={5}
            value={timeStr || timeToString(value)}
            disabled={props.disabled}
            onChange={(e) => setTimeStr(e.target.value)}
            onBlur={handleChange("time")}
          />
        </Field>
      )}
      <Error error={error} />
    </div>
  );
}
