export default function Sent() {
  return (
    <section>
      <div className="inner">
        <h2 className="border">Din søknad er sendt</h2>
        <p>
          Søknaden din kan ikke endres etter at den er sendt inn.
          <br />
          Du vil finne en kopi av søknaden under fanen «
          <a href="/saker">Mine søknader</a>».
        </p>
        <p>Kulturrom vil etterspørre ytterligere dokumentasjon ved behov.</p>
      </div>
    </section>
  );
}
