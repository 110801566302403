import { uniqueId } from "lodash";

import readyHandler from "../lib/readyHandler";

function applyMobileCollapsed() {
  document.querySelectorAll(".mobile-collapsed").forEach((container) => {
    const toggleElement = container.children[0];
    const content = container.querySelector(".expandable-content");

    if (
      ["H1", "H2", "H3", "H4", "H5", "H6"].indexOf(toggleElement.tagName) == -1
    ) {
      return;
    }

    const id = uniqueId("expandable-");
    content.id = id;

    toggleElement.setAttribute("aria-controls", id);
    toggleElement.setAttribute("aria-expanded", "false");

    toggleElement.classList.add("expandable-toggle");
    toggleElement.addEventListener("click", (evt) => {
      evt.preventDefault();
      container.classList.toggle("expandable-expanded");
      if (container.classList.contains("expandable-expanded")) {
        toggleElement.setAttribute("aria-expanded", "true");
      } else {
        toggleElement.setAttribute("aria-expanded", "false");
      }
    });
  });
}

export default function mobileCollapsed() {
  readyHandler.start(applyMobileCollapsed);
}
