import CaseStatusItem, { Props as ParentProps } from "./CaseStatusItem";
import { formatDate } from "../../lib/dates";

interface Props extends Omit<ParentProps<CaseNote>, "className"> {
  status_options: CaseStatusOption[];
}

export default function CaseNoteItem(props: Props) {
  const { status } = props;

  const changes = [];

  if (status.status) {
    const statusLabel = props.status_options.filter(
      (o) => o[1] == status.status
    )[0][0];
    changes.push(`Status: ${statusLabel}`);
  }

  if (status.agreement_due) {
    changes.push(`Avtalefrist: ${formatDate(status.agreement_due)}`);
  } else if (status.agreement_due_enabled === false) {
    changes.push("Avtalefrist: Ingen");
  }

  if (status.report_due) {
    changes.push(`Rapportfrist: ${formatDate(status.report_due)}`);
  } else if (status.report_due_enabled === false) {
    changes.push("Rapportfrist: Ingen");
  }

  if (status.case_processor) {
    changes.push(`Saksbehandler: ${status.case_processor.name}`);
  }

  return (
    <CaseStatusItem className="case-note" {...props}>
      {changes.length > 0 && (
        <div className="status-changes">
          {changes.map((s, i) => (
            <span key={i}>
              {s}
              <br />
            </span>
          ))}
        </div>
      )}
    </CaseStatusItem>
  );
}
