import { createRef, useState, KeyboardEvent, RefObject } from "react";
import Validator from "./Validator";
import useMaybeControlledValue from "../useMaybeControlledValue";

interface InputListeners {
  ref: RefObject<HTMLElement>;
  onBlur: React.FocusEventHandler<HTMLElement>;
  onFocus: React.FocusEventHandler<HTMLElement>;
  onKeyDown?: (evt: KeyboardEvent<HTMLElement>) => void;
}

function formatNumber(value: number | string) {
  const formatted = new Intl.NumberFormat("nb-NO", { style: "decimal" }).format(
    +value
  );
  if (formatted === "NaN") {
    return `${value}`;
  } else {
    return formatted;
  }
}

export function formattedValue(value: string, validations: string[] | null) {
  if (!validations) {
    validations = [];
  }

  if (value && validations.indexOf("decimal") !== -1) {
    const parts = value.split(".");
    parts[0] = formatNumber(parts[0]);
    return parts.join(",");
  }

  if (value && validations.indexOf("number") !== -1) {
    return formatNumber(value);
  }

  if (value && validations.indexOf("accountNumber") !== -1) {
    const match = value.match(/([\d]{4})\s*([\d]{1,2})\s*(.*)/);
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]}`.trim();
    }
  }

  if (value && validations.indexOf("organizationNumber") !== -1) {
    const match = value.match(/([\d]{3})([\d]{1,3})(.*)/);
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]}`.trim();
    }
  }
  return value;
}

function isNumber(validations: string[]): boolean {
  return (
    validations.indexOf("accountNumber") !== -1 ||
    validations.indexOf("number") !== -1 ||
    validations.indexOf("year") !== -1 ||
    validations.indexOf("organizationNumber") !== -1 ||
    validations.indexOf("postalCode") !== -1
  );
}

function parseValue(value: string, validations: string[]) {
  if (
    validations.indexOf("decimal") !== -1 ||
    validations.indexOf("number") !== -1
  ) {
    // Replace minus with hyphen.
    value = value.replace(/^−/, "-");
  }

  if (validations.indexOf("decimal") !== -1) {
    value = value.replace(/,/, ".").replace(/[^\d-.]/g, "");
  }

  if (isNumber(validations)) {
    value = value.replace(/[^\d-]/g, "");
  }

  return value;
}

export function nameToId(str: string) {
  if (!str) {
    return null;
  }
  return str.replace(/\[/g, "-").replace(/\]/g, "");
}

export function textFieldInitializer(props: {
  value: string;
  validations?: FormInput.Validation[];
}) {
  const { value } = props;
  const validations = props.validations || [];

  return () => {
    if (value === null) {
      return "";
    }
    if (validations.indexOf("decimal") !== -1) {
      return value.replace(/\.0$/, "");
    }
    return value;
  };
}

function useFormInputListeners(
  ref: RefObject<HTMLElement>,
  props: {
    onKeyDown?: (evt: KeyboardEvent<HTMLElement>) => void;
    readonly?: boolean;
  }
): [boolean, InputListeners] {
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);
    if (props.readonly) {
      setTimeout(() => {
        if (ref.current && ref.current instanceof HTMLInputElement) {
          ref.current.select();
        }
      }, 34);
    }
  };

  const listeners: InputListeners = {
    ref: ref,
    onBlur: () => {
      setFocus(false);
    },
    onFocus: handleFocus
  };

  if (props.onKeyDown) {
    listeners.onKeyDown = props.onKeyDown;
  }

  return [focus, listeners];
}

type UseInputProps = FormInput.Base<unknown> &
  FormInput.Validatable & {
    readonly?: boolean;
    limit?: number;
    inputRef?: RefObject<HTMLElement>;
  };

export default function useInput<T extends UseInputProps, V = T["value"]>(
  props: T,
  initializer?: () => V
): {
  value: V;
  setValue: (val: V) => void;
  listeners: InputListeners;
  focus: boolean;
  error?: string;
} {
  const inputRef: RefObject<HTMLElement> =
    props.inputRef || createRef<HTMLElement>();
  const validations: FormInput.Validation[] = props.validations || [];

  const [changed, setChanged] = useState(false);
  const [value, setValue] = useMaybeControlledValue<V>({
    value: props.value,
    onChange: props.onChange,
    initializer: initializer
  });
  const [focus, listeners] = useFormInputListeners(inputRef, props);

  let error = (!changed && props.error) || null;

  if (!focus && !error) {
    const validationErrors = new Validator<V>(value, {
      presence: false,
      optional: props.optional,
      validations: validations,
      limit: props.limit
    }).errors();
    if (validationErrors) {
      error = validationErrors[0];
    }
  }

  const setPreparedValue = (v: V | string) => {
    let newValue = v;

    if (props.readonly) {
      return;
    }

    if (typeof newValue === "string") {
      newValue = parseValue(newValue, validations) as V;
    }

    setValue(newValue);
    setChanged(true);
  };

  return {
    value: value,
    setValue: setPreparedValue,
    listeners: listeners,
    error: error,
    focus: focus
  };
}
