import { useEffect } from "react";

import useToastStore from "../../stores/useToastStore";
import { csrfToken, xhr } from "../../lib/request";

import ButtonSection from "./ButtonSection";
import ReportForm from "./ReportForm";
import useReport from "./useReport";

interface Props {
  report: Report.Report;
}

export default function EditReport(props: Props) {
  const report = props.report;

  const [record, dispatch] = useReport(report);
  const attributes = record.attributes;

  const locked = record.submit_status !== "draft";

  const { notice } = useToastStore((state) => state);

  useEffect(() => {
    if (record.id && record.url) {
      history.replaceState({}, "", record.url);
    }
  });

  const updateAttribute = (attr: string, value: unknown) => {
    dispatch({ type: "updateAttributes", payload: { [attr]: value } });
  };

  const save = (recordData: Partial<Report.Data>, callback?: () => void) => {
    const method = record.id ? "PUT" : "POST";

    xhr(
      record.url,
      { method: method, authToken: csrfToken() },
      (response: Report.BackendResponse) => {
        if (response.redirect) {
          window.location.assign(response.redirect);
        } else {
          dispatch({ type: "update", payload: response });
          dispatch({ type: "setChanged", payload: false });
          if (callback) {
            callback();
          }
        }
      }
    ).send(JSON.stringify({ report: { data: attributes, ...recordData } }));
  };

  const handleSave = (evt: React.MouseEvent) => {
    evt.preventDefault();
    save({}, () => {
      notice("Dine endringer ble lagret");
    });
  };

  const handleCancelSigning = (evt: React.MouseEvent) => {
    evt.preventDefault();
    save({ submit_status: "draft" });
  };

  const handleSigning = (evt: React.MouseEvent) => {
    evt.preventDefault();
    save({ submit_status: "signing" });
  };

  return (
    <ReportForm
      report={report}
      attributes={attributes}
      locked={locked}
      updateAttribute={updateAttribute}>
      {record.submit_status === "draft" && (
        <section>
          <div className="inner">
            <div className="buttons">
              <p>
                Alle felter må fylles ut og det må lastes opp vedlegg før
                knappen «Start Signering» aktiveres.
              </p>
              <button
                className="primary"
                disabled={!record.changed}
                onClick={handleSave}>
                Lagre
              </button>
              <button
                className="primary"
                disabled={!record.valid}
                onClick={handleSigning}>
                Start signering
              </button>
            </div>
          </div>
        </section>
      )}
      {record.submit_status === "signing" && (
        <ButtonSection>
          <button
            className="primary"
            disabled={!record.valid}
            onClick={handleSigning}>
            Gå til signering
          </button>
          <button onClick={handleCancelSigning}>Avbryt signering</button>
        </ButtonSection>
      )}
    </ReportForm>
  );
}
