import Form, { Props } from "./Form";

function Header() {
  return (
    <div className="header">
      <h3>Melding fra søker</h3>
    </div>
  );
}

export default function ApplicantCaseMessageForm(props: Props) {
  return (
    <Form
      {...props}
      header={<Header />}
      sendButtonLabel="Lagre"
      storageScope={`ApplicantCaseMessageForm:${props.case_id}`}
    />
  );
}
