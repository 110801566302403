import {
  DndContext,
  DragEndEvent,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import Option from "./Option";
import { OptionAction, OptionValue } from "./EditOptionsField";

type Props = {
  options: OptionValue[];
  optionsType: "array" | "string";
  dispatch: (action: OptionAction) => void;
};

export default function Options(props: Props) {
  const { options, optionsType, dispatch } = props;

  const newOption: Form.Option = optionsType == "array" ? ["", "", ""] : "";

  const sensors = useSensors(useSensor(PointerSensor));

  const addOption = (args?: { after?: OptionValue }) => {
    dispatch({
      type: "add",
      payload: { ...args, value: newOption }
    });
  };

  const handleAddOption = (evt: React.MouseEvent) => {
    evt.preventDefault();
    addOption();
  };

  const handleDragEnd = (evt: DragEndEvent) => {
    const { active, over } = evt;

    if (active.id !== over.id) {
      const ids = options.map((o) => o._id);
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      dispatch({
        type: "reorder",
        payload: arrayMove(options, oldIndex, newIndex)
      });
    }
  };

  return (
    <div className="edit-options">
      <div className="edit-options-list">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}>
          <SortableContext
            items={options.map((o) => o._id)}
            strategy={verticalListSortingStrategy}>
            {options.map((o) => (
              <Option
                key={o._id}
                addOption={addOption}
                option={o}
                dispatch={dispatch}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <div className="new-option">
        <button onClick={handleAddOption}>
          <i className="material-icons">add</i>
          Legg til
        </button>
      </div>
    </div>
  );
}
