import { ReactNode, useRef, useState } from "react";
import Pagination from "./Pagination";
import useFilter, { UseFilterProps } from "./CasesWithFilter/useFilter";
import useSort from "./Cases/useSort";
import List from "./Cases/List";
import Search from "./Cases/Search";

interface Props extends UseFilterProps {
  cases: Cases.Case[];
  views: Cases.MessageViews;
  forms: Cases.Form[];
  status_options: CaseStatusOption[];
  selected: Cases.Case[];
  renderActions?: (cases: Cases.Case[]) => ReactNode;
  updateSelection?: (selected: Cases.Case[]) => void;
}

export default function Cases(props: Props) {
  const perPage = 50;
  const scrollRef = useRef<HTMLDivElement>();

  props.cases.forEach((c) => {
    c.unread_messages = c.messages_count - (props.views[c.id] || 0);
  });

  const [filter, updateFilter] = useFilter(props);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useSort();

  const updateSort = (sort: Cases.Sort) => {
    setSort(sort);
    clearSelection();
  };

  const clearSelection = () => {
    if (props.updateSelection) {
      props.updateSelection([]);
    }
  };

  const filterCases = () => {
    return sortCases(filter.apply(props.cases));
  };

  const onPageChange = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1);
    clearSelection();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    scrollToCases();
  };

  const pageCount = (cases: Cases.Case[]) => {
    return Math.ceil(cases.length / perPage);
  };

  const pagedCases = (cases: Cases.Case[]) => {
    return cases.slice((page - 1) * perPage, page * perPage);
  };

  const pageHrefBuilder = () => {
    return "";
  };

  const scrollToCases = () => {
    if (!scrollRef.current || !window) {
      return;
    }

    const elem = scrollRef.current,
      rect = elem.getBoundingClientRect(),
      bodyRect = document.body.getBoundingClientRect(),
      offset = rect.top - bodyRect.top - 40;

    if (window.scrollY > offset) {
      window.scrollTo(0, offset);
    }
  };

  const sortCases = (cases: Cases.Case[]) => {
    const { column, reverse } = sort;
    let reversed = reverse ? -1 : 1;

    if (
      ["submitted_at", "updated_at", "unread_messages"].indexOf(column) !== -1
    ) {
      reversed = reversed * -1;
    }

    const sortValue = (c: Cases.Case) => {
      let value: string | boolean | Date = "";
      if (column == "form_name") {
        value = props.forms.filter((f) => f.slug == c.form_slug)[0].name;
      } else if (column == "process_status") {
        if (c.process_status) {
          value = c.process_status.label;
        }
      } else if (column == "status") {
        value = props.status_options.filter((o) => o[1] == c.status)[0][0];
      } else if (column == "unread_messages") {
        value = c.unread_messages ? true : false;
      } else {
        value = c[column];
      }

      if (typeof value == "string") {
        value = value.trim();
      }

      return value;
    };

    return [...cases].sort((caseA, caseB) => {
      const a = sortValue(caseA);
      const b = sortValue(caseB);

      if (a > b) {
        return 1 * reversed;
      } else if (a < b) {
        return -1 * reversed;
      } else if (caseA.submitted_at < caseB.submitted_at) {
        return 1 * (reverse ? -1 : 1);
      } else if (caseA.submitted_at > caseB.submitted_at) {
        return -1 * (reverse ? -1 : 1);
      }
      return 0;
    });
  };

  const filteredCases = filterCases();
  const cases = pagedCases(filteredCases);

  const pagination = cases.length > 0 && (
    <Pagination
      hrefBuilder={pageHrefBuilder}
      onPageChange={onPageChange}
      page={page}
      pageCount={pageCount(filteredCases)}
    />
  );

  return (
    <div className="saksbehandler-cases" ref={scrollRef}>
      <Search
        search={filter.state.search}
        updateSearch={(s) => updateFilter({ search: s })}
      />
      {pagination}
      {props.renderActions && props.renderActions(cases)}
      <List
        cases={cases}
        forms={props.forms}
        status_options={props.status_options}
        selected={props.selected}
        sort={sort}
        updateSelection={props.updateSelection}
        updateSort={updateSort}
      />
      {pagination}
    </div>
  );
}
