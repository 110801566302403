import { useEffect, useState } from "react";

interface StoredSort {
  [path: string]: Cases.Sort;
}

export default function useSort(): [Cases.Sort, (newSort: Cases.Sort) => void] {
  const [sort, setSort] = useState<Cases.Sort>({
    column: "submitted_at",
    reverse: false
  });

  useEffect(() => {
    // Restore sort order
    if (
      typeof window !== "undefined" &&
      window.localStorage &&
      typeof window.localStorage.caseSort == "string"
    ) {
      const caseSort = JSON.parse(window.localStorage.caseSort) as StoredSort;
      if (caseSort[document.location.pathname]) {
        setSort(caseSort[document.location.pathname]);
      }
    }
  }, []);

  const saveSort = (sort: Cases.Sort) => {
    // Save sort order
    if (typeof window !== "undefined" && window.localStorage) {
      let caseSort: StoredSort = {};
      if (typeof window.localStorage.caseSort == "string") {
        caseSort = JSON.parse(window.localStorage.caseSort) as StoredSort;
      }
      caseSort[document.location.pathname] = sort;
      window.localStorage.caseSort = JSON.stringify(caseSort);
    }
  };

  const updateSort = (sort: Cases.Sort) => {
    setSort(sort);
    saveSort(sort);
  };

  return [sort, updateSort];
}
