import Description from "./Description";
import EditButton from "./EditButton";
import EditDescriptionField from "./EditDescriptionField";
import EditField from "./EditField";
import EditOptionsField from "./EditOptionsField";
import { StoreAction } from "./store";

interface FieldProps {
  field: Form.Field;
  dispatch: (action: StoreAction) => void;
}

const typeLabels = {
  check: "Avkrysning",
  text: "Kort tekst",
  textarea: "Tekst",
  select: "Valg",
  radio: "Radio",
  files: "Filopplastning",
  description: "Beskrivelse",
  calculation: "Utregning"
};

export default function Field(props: FieldProps) {
  const { field } = props;
  const { label, type, description } = field;

  let size = field.size;

  if (!size) {
    if (type == "description") {
      size = 12;
    } else if (type == "files" || type == "textarea") {
      size = 9;
    } else {
      size = 4;
    }
  }

  const form = (closeModal: (evt: React.MouseEvent) => void) => {
    const formProps = {
      closeModal: closeModal,
      dispatch: props.dispatch
    };

    switch (type) {
      case "radio":
      case "select":
        return <EditOptionsField field={field} {...formProps} />;
      case "description":
        return <EditDescriptionField field={field} {...formProps} />;
      default:
        return <EditField field={field} {...formProps} />;
    }
  };

  return (
    <div className={`form-field size-${size}`}>
      <EditButton form={form} />
      {label}
      <div className="type">{typeLabels[type]}</div>
      {description && <Description str={description} />}
    </div>
  );
}
