const monthNames = [
  "januar",
  "februar",
  "mars",
  "april",
  "mai",
  "juni",
  "juli",
  "august",
  "september",
  "oktober",
  "november",
  "desember"
];

const zeroPad = (num: number | string) => {
  let s = num.toString();
  while (s.length < 2) {
    s = "0" + s;
  }
  return s;
};

// 12.08.2021
export function formatCompactDate(arg: string | Date) {
  const date = new Date(arg);
  return [
    zeroPad(date.getDate()),
    zeroPad(date.getMonth() + 1),
    date.getFullYear()
  ].join(".");
}

export function formatDate(arg: string | Date) {
  const date = new Date(arg);

  return `${date.getDate()}. ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`;
}

export function formatTime(arg: string | Date) {
  const date = new Date(arg);
  return [zeroPad(date.getHours()), zeroPad(date.getMinutes())].join(":");
}

// 03.08.2021, 09:45
export function formatCompactDateTime(arg: string | Date) {
  return `${formatCompactDate(arg)}, ${formatTime(arg)}`;
}

// 3. august 2021, 09:45
export function formatDateTime(arg: string | Date) {
  return `${formatDate(arg)}, ${formatTime(arg)}`;
}
