import Description from "./Description";
import EditBlock from "./EditBlock";
import EditButton from "./EditButton";
import Row from "./Row";
import { StoreAction } from "./store";

interface BlockProps {
  block: Form.Block;
  dispatch: (action: StoreAction) => void;
}

export default function Block(props: BlockProps) {
  const { name, description, fields } = props.block;

  const form = (closeModal: () => void) => {
    return (
      <EditBlock
        block={props.block}
        closeModal={closeModal}
        dispatch={props.dispatch}
      />
    );
  };

  return (
    <div className="block">
      <h3>
        <EditButton form={form} />
        {name ? name : <span className="no-description">Uten navn</span>}
      </h3>
      {description ? (
        <Description str={description} />
      ) : (
        <div className="description">
          <p className="no-description">Ingen beskrivelse</p>
        </div>
      )}
      {fields.map((r) => (
        <Row fields={r} dispatch={props.dispatch} key={fields.indexOf(r)} />
      ))}
    </div>
  );
}
