import { useCallback, useEffect, useState } from "react";
import Cases from "./Cases";
import CaseFilter, { CaseFilterState } from "./CasesWithFilter/CaseFilter";
import Actions from "./CasesWithFilter/Actions";
import Filter from "./CasesWithFilter/Filter";

interface Props {
  cases: Cases.Case[];
  views: Cases.MessageViews;
  forms: Cases.Form[];
  status_options: CaseStatusOption[];
  enableFilter: boolean;
  case_processors: CaseProcessorOption[];
  priority_options: Priority[];
  bulk_update: boolean;
}

function filterOptions(props: Props) {
  return {
    case_processor: props.case_processors
      .map((p) => `${p[1]}`)
      .concat(["none"]),
    form: props.forms.map((f) => f.slug),
    status: props.status_options.map((o) => o[1]),
    priority: props.priority_options.map((o) => `${o[1]}`)
  };
}

export default function CasesWithFilter(props: Props) {
  const [cases, setCases] = useState(props.cases);
  const [selected, setSelected] = useState<Cases.Case[]>([]);
  const [filter, setFilter] = useState(
    new CaseFilter({}, filterOptions(props))
  );

  const updateFilter = useCallback((newState: CaseFilterState) => {
    setSelected([]);
    setFilter((f) => f.update(newState));
  }, []);

  // Restore state
  useEffect(() => {
    if ("localStorage" in window && window.localStorage.caseFilter) {
      updateFilter(
        JSON.parse(localStorage.caseFilter as string) as CaseFilterState
      );
    }
  }, [updateFilter]);

  // Save state
  useEffect(() => {
    if ("localStorage" in window) {
      window.localStorage.caseFilter = JSON.stringify(filter.saveState());
    }
  }, [filter]);

  const filteredCases = filter.apply(cases);

  const updateCases = (newCases: Cases.Case[]) => {
    const newMap: Record<number, Cases.Case> = newCases.reduce((obj, c) => {
      obj[c.id] = c;
      return obj;
    }, {});

    const mergeCases = (existing: Cases.Case[]) => {
      return existing.map((c: Cases.Case) => {
        if (Object.prototype.hasOwnProperty.call(newMap, c.id)) {
          return newMap[c.id];
        } else {
          return c;
        }
      });
    };

    setCases(mergeCases(cases));
    setSelected([]);
  };

  const renderActions = (cases: Cases.Case[]) => {
    if (props.bulk_update) {
      return (
        <Actions
          cases={cases}
          filteredCases={filteredCases}
          selected={selected}
          case_processors={props.case_processors}
          status_options={props.status_options}
          updateCases={updateCases}
          updateSelection={setSelected}
        />
      );
    } else {
      return "";
    }
  };

  return (
    <div className="filterable-cases">
      <Filter
        case_processors={props.case_processors}
        filter={filter}
        forms={props.forms}
        priority_options={props.priority_options}
        status_options={props.status_options}
        updateFilter={updateFilter}
        filteredCount={filteredCases.length}
      />
      <Cases
        cases={filteredCases}
        filter={filter}
        views={props.views}
        forms={props.forms}
        selected={selected}
        updateFilter={updateFilter}
        updateSelection={props.bulk_update ? setSelected : null}
        status_options={props.status_options}
        renderActions={renderActions}
      />
    </div>
  );
}
