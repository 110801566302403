import { useReducer } from "react";
import { uniqueId } from "lodash";

export type StoreAction =
  | { type: "updateTab"; payload: Form.Tab }
  | { type: "updateBlock"; payload: Form.Block }
  | { type: "updateField"; payload: Form.Field };

type State = Form.Tab[];

const findTab = (state: State, block: Form.Block) => {
  return state.filter((tab) => {
    return tab.blocks.map((b) => b._id).indexOf(block._id) != -1;
  })[0];
};

const findBlock = (state: State, field: Form.Field) => {
  const fields: Record<string, Form.Block> = {};
  state.forEach((tab) => {
    tab.blocks.forEach((block) => {
      block.fields.forEach((row) => {
        row.forEach((f) => (fields[f._id] = block));
      });
    });
  });
  return fields[field._id];
};

// Assign unique IDs to each component
const prepareState = (state: State): State => {
  return state.map((tab) => {
    return {
      ...tab,
      _id: uniqueId("tab_"),
      blocks: tab.blocks.map((block) => {
        return {
          ...block,
          _id: uniqueId("block_"),
          fields: block.fields.map((row) => {
            return row.map((field) => {
              return {
                ...field,
                _id: uniqueId("field_")
              };
            });
          })
        };
      })
    };
  });
};

const updateTab = (state: State, newTab: Form.Tab) => {
  return state.map((tab: Form.Tab) => {
    if (tab._id == newTab._id) {
      return newTab;
    } else {
      return tab;
    }
  });
};

const updateBlock = (state: State, newBlock: Form.Block) => {
  const tab = findTab(state, newBlock);
  return updateTab(state, {
    ...tab,
    blocks: tab.blocks.map((block) => {
      if (block._id == newBlock._id) {
        return newBlock;
      } else {
        return block;
      }
    })
  });
};

const updateField = (state: State, newField: Form.Field) => {
  const block = findBlock(state, newField);
  return updateBlock(state, {
    ...block,
    fields: block.fields.map((row) => {
      return row.map((field) => {
        if (field._id == newField._id) {
          return newField;
        } else {
          return field;
        }
      });
    })
  });
};

const reducer = (state: State, action: StoreAction) => {
  switch (action.type) {
    case "updateTab":
      return updateTab(state, action.payload);
    case "updateBlock":
      return updateBlock(state, action.payload);
    case "updateField":
      return updateField(state, action.payload);
    default:
      throw new Error();
  }
};

export function useTabStore(state: State) {
  return useReducer(reducer, state, prepareState);
}

// Strip unique IDs before saving
export function prepareData(obj: unknown) {
  if (Array.isArray(obj)) {
    return obj.map(prepareData) as unknown;
  } else if (typeof obj === "object" && obj !== null) {
    const result = {};

    for (const key in obj) {
      if (key !== "_id") {
        result[key] = prepareData(obj[key]) as unknown;
      }
    }
    return result;
  } else {
    return obj;
  }
}
