import { Validator } from "../FormInput";
import { CaseFormState } from "./useCaseForm";

function isValid(state: CaseFormState, field: Form.Field) {
  const data = state.data;
  const value = data[field.name];

  let presence = field.type != "check";

  // Skip fields in a conditional block
  if (field.block.condition && !data[field.block.condition]) {
    return true;
  }

  if (field.enableIf && data[field.enableIf[0]] != field.enableIf[1]) {
    return true;
  }

  if (field.requiredUnless && data[field.requiredUnless]) {
    return true;
  }

  if (field.required_group) {
    presence =
      state.fields
        .filter((f) => f.required_group == field.required_group)
        .filter((f) => data[f.name]).length == 0;
  }

  if (field.type == "files") {
    return field.optional || (value && (value as Attachment[]).length > 0);
  }

  const validator = new Validator(value, {
    presence: presence,
    optional: field.optional,
    validations: field.validations,
    limit: field.limit
  });
  return validator.valid();
}

export function invalidFields(state: CaseFormState) {
  return state.fields.filter((f) => !isValid(state, f));
}
