import { useState } from "react";
import { CheckBox, Select, TextArea, TextField } from "../FormInput";
import useToastStore from "../../stores/useToastStore";
import { csrfToken } from "../../lib/request";

interface Props {
  decision: MeetingCase.Decision;
  url: string;
  updateCase: (c: MeetingCase.MeetingCase) => void;
}

interface State {
  body: string;
  comments: string;
  sum: string;
  deductible: string;
  locked: boolean;
  summary: string;
  status: string;
}

interface DecisionResponse {
  meeting_case: MeetingCase.MeetingCase;
}

export default function DecisionForm(props: Props) {
  const { decision, updateCase, url } = props;

  const [state, setState] = useState<State>({
    body: decision.body || "",
    comments: decision.comments || "",
    sum: decision.sum ? `${decision.sum}` : "",
    deductible: decision.deductible ? `${decision.deductible}` : "",
    locked: decision.locked || false,
    summary: decision.summary || "",
    status: decision.status
  });
  const { notice } = useToastStore((state) => state);

  const updateState = (newState: Partial<State>) => {
    setState({ ...state, ...newState });
  };

  const hasChanged = () => {
    let changed = false;
    if (state.locked != decision.locked) {
      changed = true;
    }
    ["body", "comments", "sum", "deductible", "status", "summary"].forEach(
      (p) => {
        if (state[p] !== (decision[p] || "")) {
          changed = true;
        }
      }
    );
    return changed;
  };

  const formData = () => {
    return state;
  };

  const { body, comments, sum, deductible, locked, status, summary } = state;

  const statusOptions: FormInput.Option[] = [
    ["Ubehandlet", "undecided"],
    ["Tilslag", "granted"],
    ["Avslått", "rejected"],
    ["Utsatt", "delayed"]
  ];

  const save = (evt: React.MouseEvent) => {
    evt.preventDefault();

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("X-CSRF-Token", csrfToken());
    xhr.onload = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const response = JSON.parse(xhr.responseText) as DecisionResponse;
        updateCase(response.meeting_case);
        notice("Dine endringer ble lagret");
      }
    };
    xhr.send(JSON.stringify({ decision: formData() }));
  };

  return (
    <form className="decision-form">
      <h4>Vedtak</h4>
      <div className="row">
        <Select
          disabled={decision.locked}
          options={statusOptions}
          name="decision[status]"
          label="Vedtak"
          value={status}
          size={3}
          onChange={(v: string) => updateState({ status: v })}
        />
        <TextField
          disabled={decision.locked}
          name="decision[sum]"
          label="Sum"
          value={sum}
          size={5}
          onChange={(v: string) => updateState({ sum: v })}
          validations={["decimal"]}
        />
        <TextField
          disabled={decision.locked}
          name="decision[deductible]"
          label="Egenandel (%)"
          value={deductible}
          size={4}
          onChange={(v: string) => updateState({ deductible: v })}
          validations={["decimal"]}
        />
      </div>
      <div className="row">
        <TextArea
          disabled={decision.locked}
          name="decision[body]"
          label="Vedtakstekst"
          value={body}
          size={8}
          onChange={(v: string) => updateState({ body: v })}
        />
        <TextArea
          disabled={decision.locked}
          name="decision[comments]"
          label="Intern merknad"
          value={comments}
          size={4}
          onChange={(v: string) => updateState({ comments: v })}
        />
      </div>
      <h4>Tilskuddsliste</h4>
      <div className="row">
        <TextArea
          disabled={decision.locked}
          name="decision[summary]"
          label="Tilskudd til"
          value={summary}
          size={8}
          onChange={(v: string) => updateState({ summary: v })}
        />
      </div>
      <div className="row">
        <CheckBox
          disabled={decision.locked}
          name="decision[locked]"
          label="Vedtak låst"
          value={locked}
          onChange={(v: boolean) => updateState({ locked: v })}
        />
      </div>
      <div className="buttons tight">
        <button
          className="primary"
          disabled={decision.locked || !hasChanged()}
          onClick={save}>
          Lagre
        </button>
      </div>
    </form>
  );
}
