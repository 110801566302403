import CaseFilter, { CaseFilterState } from "./CaseFilter";
import FlagToggleButton from "./FlagToggleButton";
import { SimpleSelect, stringOption } from "../FormInput";

interface Props {
  case_processors: CaseProcessorOption[];
  filter: CaseFilter;
  filteredCount: number;
  forms: Cases.Form[];
  priority_options: Priority[];
  status_options: CaseStatusOption[];
  updateFilter: (state: Partial<CaseFilterState>) => void;
}

export default function Filter(props: Props) {
  const {
    case_processors,
    filter,
    forms,
    status_options,
    priority_options,
    filteredCount,
    updateFilter
  } = props;

  const { form, case_processor, priority, status, flagged, archived } =
    filter.state;

  const changeArchived = (archived: string) => {
    updateFilter({ archived: archived });
  };

  const changeCaseProcessor = (id: string) => {
    updateFilter({ case_processor: id });
  };

  const changeForm = (form: string) => {
    updateFilter({ form: form });
  };

  const changeFlagged = (flagged: boolean) => {
    updateFilter({ flagged: flagged });
  };

  const changePriority = (priority: string) => {
    updateFilter({ priority: priority });
  };

  const changeStatus = (status: string) => {
    updateFilter({ status: status });
  };

  return (
    <div className="case-list-filter">
      <div className="selects">
        <SimpleSelect
          label="Saksbehandler"
          name="caseProcessor"
          blankOption="Alle saksbehandlere"
          options={[
            ["Ingen saksbehandler", "none"],
            ...case_processors.map(stringOption)
          ]}
          value={case_processor}
          onChange={changeCaseProcessor}
        />
        <SimpleSelect
          label="Ordning"
          name="formName"
          blankOption="Alle ordninger"
          options={forms.map((f) => [f.name, f.slug])}
          value={form}
          onChange={changeForm}
        />
        <SimpleSelect
          label="Status"
          name="status"
          blankOption="Alle statuser"
          options={status_options}
          value={status}
          onChange={changeStatus}
        />
        <SimpleSelect
          label="Prioritet"
          name="prioritet"
          blankOption="Alle saker"
          options={priority_options.map(stringOption)}
          value={priority}
          onChange={changePriority}
        />
        <SimpleSelect
          label="Åpne/arkivert"
          name="archived"
          options={[
            ["Åpne", "open"],
            ["Arkivert", "archived"],
            ["Alle", "all"]
          ]}
          value={archived}
          onChange={changeArchived}
        />
        <FlagToggleButton value={flagged} onChange={changeFlagged} />
        <span className="filtered-count">{filteredCount}</span>
      </div>
    </div>
  );
}
