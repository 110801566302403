import { ChangeEvent } from "react";

import Error from "./Error";
import Field from "./Field";
import Tooltip from "./Tooltip";

import useInput, { nameToId } from "./useInput";

export default function CheckBox(props: FormInput.CheckBox) {
  const { label, name, tooltip } = props;

  const { value, setValue, listeners, error } =
    useInput<FormInput.CheckBox>(props);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.checked);
  };

  return (
    <Field type="check-box" size={props.size}>
      <input name={name} type="hidden" value="0" />
      <input
        id={nameToId(name)}
        disabled={props.disabled}
        type="checkbox"
        name={name}
        className={error ? "warn" : ""}
        checked={value ? true : false}
        value="1"
        readOnly={props.readonly}
        onChange={handleChange}
        {...listeners}
        ref={listeners.ref as React.RefObject<HTMLInputElement>}
      />
      <label htmlFor={nameToId(name)}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {tooltip && <Tooltip tooltip={tooltip} />}
      </label>
      <Error error={error} />
    </Field>
  );
}
