import { ChangeEvent } from "react";

import Error from "./Error";
import Field from "./Field";
import Label from "./Label";
import { optionLabel, optionValue } from "./options";
import useInput, { nameToId } from "./useInput";

export default function Radio(props: FormInput.Radio) {
  const { label, name, options } = props;

  const { value, setValue, error } = useInput<FormInput.Radio>(props);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
  };

  return (
    <Field type="radio" size={props.size}>
      {label && <Label label={`${label}:`} tooltip={props.tooltip} />}
      {options.map((o, i) => (
        <div className="radio-option" key={i}>
          <input
            id={nameToId(name + "-" + optionValue(o))}
            type="radio"
            disabled={props.disabled}
            key={i}
            name={name}
            value={optionValue(o)}
            checked={value == optionValue(o)}
            readOnly={props.readonly}
            onChange={handleChange}
          />
          <label htmlFor={nameToId(name + "-" + optionValue(o))}>
            {optionLabel(o)}
          </label>
        </div>
      ))}
      <Error error={error} />
    </Field>
  );
}
