import { Fragment, useEffect, useState } from "react";
import { formatCompactDate } from "../lib/dates";

interface Props {
  time: Date | string;
}

export default function RelativeTime(props: Props) {
  const time =
    typeof props.time == "string" ? new Date(props.time) : props.time;

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentTime(new Date());
    }, 15000);
    return () => {
      clearInterval(id);
    };
  }, [props.time]);

  const diff = Math.ceil((time.getTime() - currentTime.getTime()) / 1000);
  const absDiff = Math.abs(diff);

  const formattedDistance = () => {
    if (absDiff > 60 * 60 * 24 * 14) {
      return formatCompactDate(time);
    }

    const [value, unit] = labelledDistance();
    const absValue = Math.floor(value);

    let label = unit;
    if (absValue > 1) {
      label = `${label}s`;
    }

    let timeStr = `${absValue} ${getLabel(label)}`;
    if (absDiff < 10) {
      return "nå";
    }
    if (absDiff < 60) {
      timeStr = "ett øyeblikk";
    }

    if (diff < 0) {
      return `${timeStr} siden`;
    } else {
      return `om ${timeStr}`;
    }
  };

  const getLabel = (key: string) => {
    return {
      second: "sekund",
      seconds: "sekunder",
      minute: "min",
      minutes: "min",
      hour: "time",
      hours: "timer",
      day: "dag",
      days: "dager",
      year: "år",
      years: "år"
    }[key];
  };

  const labelledDistance = (): [number, string] => {
    const seconds = Math.abs(diff);
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const years = days / 365;

    if (years >= 1) {
      return [years, "year"];
    } else if (days >= 1) {
      return [days, "day"];
    } else if (hours >= 1) {
      return [hours, "hour"];
    } else if (minutes >= 1) {
      return [minutes, "minute"];
    } else {
      return [seconds, "second"];
    }
  };

  return <Fragment>{formattedDistance()}</Fragment>;
}
