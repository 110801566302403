import { useState } from "react";
import { EditorState } from "draft-js";

import {
  RichTextArea,
  richTextFromHTML,
  richTextToHTML,
  TextField
} from "../FormInput";
import { StoreAction } from "./store";

interface EditFieldProps {
  field: Form.Field;
  closeModal: (evt: React.MouseEvent) => void;
  dispatch: (action: StoreAction) => void;
}

interface EditFieldState {
  label: string;
  tooltip: EditorState;
}

export default function EditField(props: EditFieldProps) {
  const { closeModal, dispatch, field } = props;

  const [state, setState] = useState<EditFieldState>({
    label: field.label,
    tooltip: richTextFromHTML(field.tooltip)
  });

  const updateState = (newState: Partial<EditFieldState>) => {
    setState({ ...state, ...newState });
  };

  const save = (evt: React.MouseEvent) => {
    dispatch({
      type: "updateField",
      payload: { ...field, ...state, tooltip: richTextToHTML(state.tooltip) }
    });
    closeModal(evt);
  };

  const tooltipEnabled = ["files"].indexOf(field.type) == -1;

  return (
    <form className="modal-form">
      <h2>Endre felt</h2>
      <div className="row">
        <TextField
          name="id"
          label="ID"
          value={field.name}
          disabled={true}
          size={12}
        />
      </div>
      <div className="row">
        <TextField
          name="label"
          label="Label"
          onChange={(val: string) => updateState({ label: val })}
          value={state.label}
          size={12}
        />
      </div>
      {tooltipEnabled && (
        <div className="row">
          <RichTextArea
            name="tooltip"
            label="Hjelpetekst"
            onChange={(val: EditorState) => updateState({ tooltip: val })}
            value={state.tooltip}
            links={true}
            simple={true}
            size={12}
          />
        </div>
      )}
      <div className="buttons">
        <button type="submit" onClick={save}>
          Oppdater
        </button>
        <button onClick={closeModal}>Avbryt</button>
      </div>
    </form>
  );
}
