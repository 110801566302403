interface CaseFilterSaveState {
  form: string;
  status: string;
  case_processor: string;
  priority: string;
  flagged: boolean;
  archived: string;
}

export interface CaseFilterState extends CaseFilterSaveState {
  search: string;
}

interface Options {
  form: string[];
  case_processor: string[];
  status: string[];
  priority: string[];
  archived: string[];
}

export default class CaseFilter {
  options: Options;
  state: CaseFilterState;

  constructor(state?: Partial<CaseFilterState>, options?: Partial<Options>) {
    this.options = { ...this.defaultOptions(), ...options };
    this.state = { ...this.defaultState(), ...state };
  }

  defaultOptions(): Options {
    return {
      form: [],
      case_processor: ["none"],
      status: [],
      priority: [],
      archived: ["open", "archived", "all"]
    };
  }

  defaultState(): CaseFilterState {
    return {
      form: "",
      status: "",
      case_processor: "",
      priority: "",
      flagged: false,
      archived: "open",
      search: ""
    };
  }

  saveState(): CaseFilterSaveState {
    return {
      form: this.state.form,
      status: this.state.status,
      case_processor: this.state.case_processor,
      priority: this.state.priority,
      flagged: this.state.flagged,
      archived: this.state.archived
    };
  }

  update(newState: Partial<CaseFilterState>) {
    const nextState: CaseFilterState = { ...this.state, ...newState };

    for (const [key, value] of Object.entries(newState)) {
      if (key in this.options) {
        const opt = this.options[key] as string[];
        if (Array.isArray(opt) && opt.indexOf(value as string) !== -1) {
          nextState[key] = value as string;
        } else {
          nextState[key] = this.defaultState()[key] as string;
        }
      }
    }

    return new CaseFilter(nextState, this.options);
  }

  apply(cases: Cases.Case[]) {
    if (this.state.archived == "open") {
      cases = cases.filter((c) => c.archived == false);
    } else if (this.state.archived == "archived") {
      cases = cases.filter((c) => c.archived == true);
    }

    if (this.state.form) {
      cases = cases.filter((c) => c.form_slug === this.state.form);
    }

    if (this.state.priority) {
      cases = cases.filter((c) => c.priority === this.state.priority);
    }

    if (this.state.status) {
      cases = cases.filter((c) => c.status === this.state.status);
    }

    if (this.state.flagged) {
      cases = cases.filter((c) => c.flagged);
    }

    if (this.state.search && this.state.search.length >= 2) {
      cases = cases.filter((c) =>
        this.fuzzyMatch(this.searchString(c), this.state.search)
      );
    }

    return this.filterCaseProcessor(cases);
  }

  filterCaseProcessor(cases: Cases.Case[]) {
    if (this.state.case_processor) {
      if (this.state.case_processor === "none") {
        cases = cases.filter((c) => !c.case_processor || !c.case_processor.id);
      } else {
        cases = cases.filter(
          (c) =>
            c.case_processor &&
            `${c.case_processor.id}` == this.state.case_processor
        );
      }
    }
    return cases;
  }

  fuzzyMatch(str: string, query: string) {
    const pattern = query
      .replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")
      .toLowerCase()
      .split(" ")
      .reduce((a, b) => a + ".*" + b);
    return new RegExp(pattern).test(str.toLowerCase());
  }

  searchString(c: Cases.Case) {
    return [
      c.case_number,
      c.organization_name,
      c.organization_number,
      c.contact_name,
      c.contact_email
    ]
      .filter((s) => s)
      .join(" ");
  }
}
