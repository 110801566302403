interface ErrorProps {
  error: string | null;
}

export default function Error(props: ErrorProps) {
  const { error } = props;

  if (!error) {
    return "";
  }

  return (
    <div className="error">
      {error}
      <i className="material-icons error-icon">info</i>
    </div>
  );
}
