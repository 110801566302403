import { useState } from "react";
import { EditorState } from "draft-js";

import {
  RichTextArea,
  richTextFromHTML,
  richTextToHTML,
  TextField
} from "../FormInput";
import { StoreAction } from "./store";

interface EditTabProps {
  tab: Form.Tab;
  closeModal: (evt: React.MouseEvent) => void;
  dispatch: (action: StoreAction) => void;
}

interface EditTabState {
  name: string;
  description: EditorState;
}

export default function EditTab(props: EditTabProps) {
  const [state, setState] = useState<EditTabState>({
    name: props.tab.name,
    description: richTextFromHTML(props.tab.description)
  });

  const updateState = (newState: Partial<EditTabState>) => {
    setState({ ...state, ...newState });
  };

  const save = (evt: React.MouseEvent) => {
    props.dispatch({
      type: "updateTab",
      payload: {
        ...props.tab,
        name: state.name,
        description: richTextToHTML(state.description)
      }
    });
    props.closeModal(evt);
  };

  return (
    <form className="modal-form">
      <h2>Endre fane</h2>
      <div className="row">
        <TextField
          name="id"
          label="ID"
          value={props.tab.id}
          disabled={true}
          size={12}
        />
      </div>
      <div className="row">
        <TextField
          name="name"
          label="Navn"
          onChange={(val: string) => updateState({ name: val })}
          value={state.name}
          size={12}
        />
      </div>
      <div className="row">
        <RichTextArea
          name="description"
          label="Beskrivelse"
          onChange={(val: EditorState) => updateState({ description: val })}
          links={true}
          simple={true}
          value={state.description}
          size={12}
        />
      </div>
      <div className="buttons">
        <button type="submit" onClick={save}>
          Oppdater
        </button>
        <button onClick={props.closeModal}>Avbryt</button>
      </div>
    </form>
  );
}
