import { useState } from "react";
import CaseFilter, { CaseFilterState } from "./CaseFilter";

type UpdateFn = (state: Partial<CaseFilterState>) => void;

export interface UseFilterProps {
  filter?: CaseFilter;
  updateFilter?: UpdateFn;
}

export default function useFilter(
  props: UseFilterProps
): [CaseFilter, UpdateFn] {
  const [uncontrolledFilter, setUncontrolledFilter] = useState(
    new CaseFilter()
  );

  const filter = props.filter || uncontrolledFilter;

  const updateFilter = (newState: Partial<CaseFilterState>) => {
    if (props.updateFilter) {
      props.updateFilter(newState);
    } else {
      setUncontrolledFilter(uncontrolledFilter.update(newState));
    }
  };

  return [filter, updateFilter];
}
