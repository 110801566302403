import { CaseFormState, CaseFormDispatch } from "./useCaseForm";
import { tabPath } from "./persistence";

interface Props {
  state: CaseFormState;
  dispatch: CaseFormDispatch;
  preview: boolean;
}

export default function Tabs(props: Props) {
  const { state, dispatch } = props;
  const tabs = state.form.tabs;

  const handleClick = (index: number) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "changeTab", payload: index });
  };

  return (
    <div className="tabs">
      <div className="inner">
        {tabs.map((tab, index) => (
          <a
            key={`tab-button-${index}`}
            className={index == state.currentTab ? "current" : ""}
            href={tabPath(state, tab, props.preview)}
            onClick={handleClick(index)}>
            {tab.name}
          </a>
        ))}
      </div>
    </div>
  );
}
