import { Fragment } from "react";
import { EditorState } from "draft-js";
import BlockTypeButton from "./BlockTypeButton";
import LinkButton from "./LinkButton";
import InlineStyleButton from "./InlineStyleButton";

interface ButtonsProps {
  onChange: (state: EditorState) => void;
  editorState: EditorState;
  links: boolean;
  readOnly: boolean;
  simple: boolean;
}

export default function Buttons(props: ButtonsProps) {
  const { onChange, editorState, links, readOnly, simple } = props;

  const selectionCollapsed = editorState.getSelection().isCollapsed();

  const inlineStyleButton = (title: string, style?: string, icon?: string) => {
    return (
      <InlineStyleButton
        disabled={readOnly}
        title={title}
        style={style}
        icon={icon}
        editorState={editorState}
        onChange={onChange}
      />
    );
  };

  return (
    <div className="actions">
      {inlineStyleButton("Bold")}
      {inlineStyleButton("Italic")}
      {inlineStyleButton("Underline")}
      {!simple && inlineStyleButton("Rød", "RED", "palette")}
      {links && (
        <LinkButton
          disabled={selectionCollapsed || readOnly}
          editorState={editorState}
          onChange={onChange}
        />
      )}
      {!simple && (
        <Fragment>
          <BlockTypeButton
            icon="title"
            disabled={readOnly}
            className="h2"
            title="Heading"
            type="header-two"
            editorState={editorState}
            onChange={onChange}
          />
          <BlockTypeButton
            icon="format_quote"
            disabled={readOnly}
            className="blockquote"
            title="Sitat"
            type="blockquote"
            editorState={editorState}
            onChange={onChange}
          />
        </Fragment>
      )}
    </div>
  );
}
