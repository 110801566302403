import { useState } from "react";

type Props<T> =
  | { value: T; onChange?: (value: T) => void }
  | { value?: unknown; initializer: () => T };

export default function useMaybeControlledValue<T>(
  props: Props<T>
): [T, (newValue: T) => void] {
  const [uncontrolledValue, setUncontrolledValue] = useState<T>(
    "initializer" in props && props.initializer
      ? props.initializer
      : (props.value as T)
  );
  if ("onChange" in props && props.onChange) {
    return [props.value, props.onChange];
  } else {
    return [uncontrolledValue, setUncontrolledValue];
  }
}
