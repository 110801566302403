import { useEffect, useRef, useState } from "react";
import Attachments from "../Attachments";
import { formatDateTime } from "../../lib/dates";

export interface Props<T = CaseStatus> {
  adminView: boolean;
  className: string;
  status: T;
}

export default function CaseStatusItem(props: React.PropsWithChildren<Props>) {
  const { adminView, className } = props;
  const { admin_message, attachments, body, person, submission, created_at } =
    props.status;
  const direction = adminView === admin_message ? "out" : "in";

  const [collapseable, setCollapseable] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const bodyRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (bodyRef.current) {
      const shouldCollapse = bodyRef.current.offsetHeight > 100;
      if (shouldCollapse != collapseable) {
        setCollapseable(shouldCollapse);
      }
    }
  }, [collapseable, collapsed, bodyRef]);

  const toggleCollapsed = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (collapseable) {
      setCollapsed(!collapsed);
    }
  };

  const senderName = () => {
    if (!person) {
      return "System";
    } else if (admin_message) {
      return `Kulturrom v/ ${person.name}`;
    } else {
      return person.name;
    }
  };

  let collapsedClass = "";
  if (collapseable) {
    collapsedClass = collapsed ? "collapsed" : "expanded";
  }

  return (
    <div className={`case-status ${className} direction-${direction}`}>
      <div className="timestamp">
        {formatDateTime(created_at)} &ndash; {senderName()}
      </div>

      {props.children}

      {body && body.trim() !== "" && (
        <div className={"body " + collapsedClass}>
          <div className="body-inner" ref={bodyRef}>
            {body.split("\n").map((line, key) => (
              <span key={key}>
                {line}
                <br />
              </span>
            ))}
          </div>
          {collapseable && collapsed && (
            <div className="collapse-overlay" onClick={toggleCollapsed} />
          )}
          {collapseable && (
            <button className="collapse-toggle" onClick={toggleCollapsed}>
              Toggle collapsed
            </button>
          )}
        </div>
      )}

      <Attachments attachments={attachments} submission={submission} />
    </div>
  );
}
