import { useReducer } from "react";

function initialState(report: Report.Report): Report.State {
  const attributes = { ...report.data };

  return {
    id: report.id,
    url: report.url,
    submit_status: report.submit_status,
    changed: false,
    valid: validate(attributes, report.type),
    attributes: attributes,
    type: report.type
  };
}

function reducer(state: Report.State, action: Report.Action): Report.State {
  switch (action.type) {
    case "setChanged":
      return { ...state, changed: action.payload };
    case "update":
      return { ...state, ...action.payload };
    case "updateAttributes":
      return {
        ...state,
        changed: true,
        attributes: { ...state.attributes, ...action.payload }
      };
    default:
      return state;
  }
}

function validatingReducer(
  state: Report.State,
  action: Report.Action
): Report.State {
  const nextState = reducer(state, action);
  return { ...nextState, valid: validate(nextState.attributes, state.type) };
}

function validate(attributes: Report.Data, type: Report.ReportType) {
  const totalCost = (attributes.costs || []).reduce(
    (v, c) => v + parseInt(`${c.sum}`),
    0
  );

  let requiredAttributes = [
    "deviations",
    "implementation",
    "account_number",
    "account_name",
    "confirm_report_power",
    "confirm_terms",
    "confirm_other_financing"
  ];

  if (type == "FinalReport") {
    requiredAttributes = [...requiredAttributes, "advance"];
  }

  if (totalCost > 100000) {
    requiredAttributes = [
      ...requiredAttributes,
      "confirm_purchasing_requirements",
      "vendors_invited",
      "vendors_delivered",
      "chosen_vendor",
      "vendor_justification",
      "effective_use"
    ];
  }

  if (totalCost > 500000) {
    requiredAttributes = [
      ...requiredAttributes,
      "confirm_qualification_requirements"
    ];
  }

  let missing = false;
  requiredAttributes.forEach((attr) => {
    if (!attributes[attr]) {
      missing = true;
    }
  });

  if ((attributes.costs || []).length < 1) {
    missing = true;
  }

  return !missing;
}

export default function useReport(
  report: Report.Report
): [Report.State, (action: Report.Action) => void] {
  const [state, dispatch] = useReducer(validatingReducer, report, initialState);
  return [state, dispatch];
}
