import Field from "./Field";
import { StoreAction } from "./store";

interface RowProps {
  fields: Form.Field[];
  dispatch: (action: StoreAction) => void;
}

export default function Row(props: RowProps) {
  const fields = props.fields;
  return (
    <div className="form-row">
      <div className="form-fields">
        {fields.map((f) => (
          <Field field={f} dispatch={props.dispatch} key={f._id} />
        ))}
      </div>
    </div>
  );
}
