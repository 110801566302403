import { ReactNode } from "react";

import CheckBox from "../FormInput/CheckBox";
import Radio from "../FormInput/Radio";
import TextArea from "../FormInput/TextArea";
import TextField from "../FormInput/TextField";
import Costs from "./Costs";
import content from "./content";

interface Props {
  report: Report.Report;
  attributes: Report.Data;
  locked: boolean;
  updateAttribute?: (key: string, value: unknown) => void;
  children?: ReactNode;
}

function prepareAccountNumber(str?: string) {
  if (str) {
    return str.replace(/[^\d]/g, "");
  } else {
    return "";
  }
}

export default function ReportForm(props: Props) {
  const { report, locked, attributes } = props;

  const disabled = locked || !props.updateAttribute;

  const handleChange = (attr: string) => (value: unknown) => {
    if (props.updateAttribute) {
      props.updateAttribute(attr, value);
    }
  };

  const totalCost = (attributes.costs || []).reduce(
    (v, c) => v + parseInt(`${c.sum}`),
    0
  );

  const strings = content(report.type);

  return (
    <form className="report-form">
      <section className="intro">
        <div className="inner">
          <h1>{strings.formName}</h1>
          <div className="description">{strings.intro}</div>
        </div>
      </section>

      <section>
        <div className="inner">
          <h2 className="border">Del 1: Alle tilskuddsmottakere</h2>
          <div className="row">
            <TextField
              name="case_number"
              label="Saksnummer"
              value={report.case_number}
              size={3}
              disabled={true}
            />
            <TextField
              name="organization_name"
              label="Tilskuddsmottaker"
              value={report.organization_name}
              size={6}
              disabled={true}
            />
            <TextField
              name="organization_number"
              label="Organisasjonsnummer"
              value={report.organization_number}
              size={3}
              disabled={true}
            />
          </div>
          <div className="row">
            <TextArea
              name="decision"
              label="Vedtak"
              value={report.decision || ""}
              size={9}
              disabled={true}
            />
          </div>
          <div className="description">
            <p>
              Tilskuddet skal benyttes iht. vedtak. Eventuelle avvik,
              justeringer eller omdisponeringer skal avklares i forkant av
              gjennomføring. Avvik fra vedtak kan føre til avkortning av
              tilskudd utbetalt. Alle avvik skal kommenteres.
            </p>
          </div>
          <div className="row">
            <Radio
              name="deviations"
              options={[
                ["Tilskuddet er brukt iht. vedtak.", "Ja"],
                [
                  "Det er gjort avvik, justeringer eller omdisponeringer.",
                  "Nei"
                ]
              ]}
              value={attributes.deviations}
              onChange={handleChange("deviations")}
              disabled={disabled}
              size={12}
            />
          </div>
          <div className="row">
            <TextArea
              name="deviation_comments"
              label="Kommenter avvik"
              onChange={handleChange("deviation_comments")}
              value={attributes.deviation_comments}
              size={9}
              disabled={disabled || attributes.deviations !== "Nei"}
            />
          </div>
          <h3>Gjennomføring</h3>
          <div className="description">{strings.implementationDescription}</div>
          <div className="row">
            <TextArea
              name="implementation"
              label="Gjennomføring av tiltak"
              onChange={handleChange("implementation")}
              value={attributes.implementation}
              size={9}
              disabled={disabled}
            />
          </div>
          <h3>{strings.costsHeading}</h3>
          <div className="description">{strings.costsDescription}</div>
          <div className="row">
            <div className="field size-9">
              <Costs
                caseId={report.case_id}
                costs={attributes.costs || []}
                onChange={handleChange("costs")}
                disabled={disabled}
                receiptRequired={report.type == "FinalReport"}
                locked={locked}
                strings={strings}
              />
            </div>
          </div>
          <div className="row">
            <TextField
              name="total_cost"
              label="Totalkostnad (summeres automatisk)"
              value={totalCost ? `${totalCost}` : ""}
              validations={["decimal"]}
              onChange={() => {}}
              size={4}
              disabled={true}
              tooltip="Hvis totalkostnaden for tiltaket overstiger
              NOK 100 000 skal del 2 av rapportskjemaet fylles ut.
              Hvis totalkostnaden for tiltaket overstiger NOK 500 000
              skal del 2 og del 3 av rapportskjemaet fylles ut."
            />
          </div>
          <div className="row">
            <TextArea
              name="comments"
              label="Eventuelle kommentarer"
              onChange={handleChange("comments")}
              value={attributes.comments}
              size={9}
              disabled={disabled}
            />
          </div>
          <h3>Utbetaling</h3>
          <div className="description">{strings.paymentDescription}</div>
          <div className="row">
            <TextField
              name="account_number"
              label={strings.accountNumber}
              onChange={handleChange("account_number")}
              value={prepareAccountNumber(attributes.account_number)}
              size={4}
              disabled={disabled}
              validations={["accountNumber"]}
            />
            <TextField
              name="account_name"
              label="Navn på kontoeier"
              onChange={handleChange("account_name")}
              value={attributes.account_name}
              size={5}
              disabled={disabled}
            />
          </div>
          {report.type == "FinalReport" && (
            <div className="row">
              <Radio
                name="advance"
                label="Er deler av tilskuddet utbetalt som forskudd"
                options={["Ja", "Nei"]}
                size={9}
                value={attributes.advance}
                onChange={handleChange("advance")}
                disabled={disabled}
              />
            </div>
          )}
          <div className="row">
            <CheckBox
              name="confirm_report_power"
              label={strings.confirmReportPower}
              value={attributes.confirm_report_power}
              onChange={handleChange("confirm_report_power")}
              size={9}
              disabled={disabled}
            />
          </div>
          <div className="row">
            <CheckBox
              name="confirm_terms"
              label={
                "Jeg bekrefter at tilskuddet er brukt i henhold til " +
                "søknad samt Kulturroms vilkår for tilskudd slik " +
                "det er beskrevet i vedtak og tilskuddsavtale."
              }
              value={attributes.confirm_terms}
              onChange={handleChange("confirm_terms")}
              size={9}
              disabled={disabled}
            />
          </div>
          <div className="row">
            <CheckBox
              name="confirm_other_financing"
              label={strings.confirmOtherFinancing}
              value={attributes.confirm_other_financing}
              onChange={handleChange("confirm_other_financing")}
              size={9}
              disabled={disabled}
            />
          </div>
        </div>
      </section>

      <section>
        <div className="inner">
          <h2 className="border">
            Del 2: Utvidet rapportskjema for innkjøp over NOK 100 000
          </h2>
          <div className="description">
            <p>
              Hvis totalkostnaden for tiltaket overstiger NOK 100 000 skal del 2
              av rapportskjemaet fylles ut.
              <br />
              Summen i feltet «Totalkostnad (summeres automatisk)» avgjør om del
              2 er obligatorisk.
            </p>
          </div>
          <div className="row">
            <CheckBox
              name="confirm_purchasing_requirements"
              label={
                "Jeg bekrefter med dette at innkjøp er gjort " +
                "iht. Kulturroms <i>Krav til innkjøp</i>."
              }
              value={attributes.confirm_purchasing_requirements}
              onChange={handleChange("confirm_purchasing_requirements")}
              size={9}
              disabled={disabled}
            />
          </div>
          <div className="row">
            <TextArea
              name="vendors_invited"
              label={"Hvilke leverandører kontaktet dere for å levere tilbud"}
              onChange={handleChange("vendors_invited")}
              value={attributes.vendors_invited}
              size={9}
              disabled={disabled}
            />
          </div>
          <div className="row">
            <TextArea
              name="vendors_delivered"
              label="Hvilke leverandører leverte tilbud"
              onChange={handleChange("vendors_delivered")}
              value={attributes.vendors_delivered}
              size={9}
              disabled={disabled}
            />
          </div>
          <div className="row">
            <TextField
              name="chosen_vendor"
              label="Navn på valgt leverandør"
              onChange={handleChange("chosen_vendor")}
              value={attributes.chosen_vendor}
              size={9}
              disabled={disabled}
            />
          </div>
          <div className="row">
            <TextArea
              name="vendor_justification"
              label="Begrunnelse for valg av leverandør"
              onChange={handleChange("vendor_justification")}
              value={attributes.vendor_justification}
              size={9}
              disabled={disabled}
            />
          </div>
          <div className="row">
            <TextArea
              name="effective_use"
              label={
                "Begrunnelse for hvorfor valgt gjennomføring er " +
                "den mest mulig effektive nytten av tilskuddet"
              }
              onChange={handleChange("effective_use")}
              value={attributes.effective_use}
              size={9}
              disabled={disabled}
            />
          </div>
        </div>
      </section>

      <section>
        <div className="inner">
          <h2 className="border">
            Del 3: Utvidet rapportskjema for innkjøp over NOK 500 000
          </h2>
          <div className="description">
            <p>
              Hvis totalkostnaden for tiltaket overstiger NOK 500 000 skal del 2
              og del 3 av rapportskjemaet fylles ut.
              <br />
              Summen i feltet «Totalkostnad (summeres automatisk)» avgjør om del
              2 og del 3 er obligatorisk
            </p>
          </div>
          <div className="row">
            <CheckBox
              name="confirm_qualification_requirements"
              label={
                "Jeg bekrefter med dette at valgt leverandør " +
                "oppfyller Kulturroms kvalifikasjonskrav."
              }
              value={attributes.confirm_qualification_requirements}
              onChange={handleChange("confirm_qualification_requirements")}
              size={9}
              disabled={disabled}
            />
          </div>
        </div>
      </section>

      {props.children}
    </form>
  );
}
