import Tooltip from "./Tooltip";

interface LabelProps {
  label: string;
  htmlFor?: string;
  tooltip?: string;
}

export default function Label(props: LabelProps) {
  const { htmlFor, label, tooltip } = props;

  return (
    <label htmlFor={htmlFor}>
      {label}
      {tooltip && <Tooltip tooltip={tooltip} />}
    </label>
  );
}
