export function optionLabel(opt: FormInput.Option): string {
  if (Array.isArray(opt)) {
    return opt[0];
  }
  return opt;
}

export function optionValue(opt: FormInput.Option): string {
  if (Array.isArray(opt)) {
    return opt[1];
  }
  return opt;
}

export function stringOption(opt: number | [string, number]): FormInput.Option {
  if (Array.isArray(opt)) {
    return [opt[0], `${opt[1]}`];
  }
  return `${opt}`;
}
